import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Nav from '../../components/Nav';
import MainFooter from '../../components/MainFooter';
import logo from '../../assets/images/big_logo.svg';
import './MainLayout.scss';

const MainLayout = ({ children }) => {
  return (
    <Container fluid className="p-0">
      <Nav />
      <div className="main-banner">
        <img src={logo} alt="MIN Capital" />
      </div>
      {children}
      <MainFooter />
    </Container>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
