import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import LandingSlider from '../../components/LandingSlider';
import companyImg from '../../assets/images/company_updated.png';
import commitmentIcon from '../../assets/images/commitment.svg';
import integrityIcon from '../../assets/images/integrity.svg';
import innovationIcon from '../../assets/images/innovation.svg';
import diamondIcon from '../../assets/images/diamond.svg';
import accountabilityIcon from '../../assets/images/accountability.svg';

const Home = () => {
  const { t } = useTranslation();
  const infoList = [
    {
      name: t('home.firstIcon'),
      icon: commitmentIcon,
    },
    {
      name: t('home.secondIcon'),
      icon: integrityIcon,
    },
    {
      name: t('home.thirdIcon'),
      icon: innovationIcon,
    },
    {
      name: t('home.fourthIcon'),
      icon: accountabilityIcon,
    },
  ];
  return (
    <>
      <LandingSlider />
      <Row noGutters className="main-content">
        <Col md={12} lg={4} className="main-container-border">
          <Row noGutters className="mr-0 mr-lg-5 h-100">
            <div className="d-flex flex-column justify-content-between">
              <div className="main-icon">
                <img src={diamondIcon} alt="" />
              </div>
              <div>
                <p className="main-column-info">{t('home.column')}</p>
              </div>
            </div>
          </Row>
        </Col>
        <Col md={12} lg={8}>
          <Row noGutters className="ml-0 ml-lg-5">
            <Col>
              <h1 className="mb-5">{t('home.title')}</h1>
              <p>{t('home.info')}</p>
              <h4>{t('home.subTitle')}</h4>
              <p>{t('home.subInfo')}</p>
              <Row noGutters className="mt-4">
                <Col md={12} lg={5}>
                  {infoList.map(info => (
                    <div key={info.name} className="main-list">
                      <img src={info.icon} alt="" />
                      <span>{info.name}</span>
                    </div>
                  ))}
                </Col>
                <div className="main-image mt-3">
                  <img src={companyImg} alt={t('home.title')} />
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Home;
