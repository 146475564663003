import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from '../hooks/ScrollToTop';
import MainRoute from './MainRoute';
import MainLayout from '../layouts/MainLayout';
import Home from '../views/Home';
import RealEstate from '../views/RealEstate';
import Financial from '../views/Financial';

const Routes = () => {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Switch>
          <MainRoute exact path="/" component={Home} layout={MainLayout} />
          <MainRoute
            exact
            path="/real-estate"
            component={RealEstate}
            layout={MainLayout}
          />
          <MainRoute exact path="/financial" component={Financial} layout={MainLayout} />
          <Redirect to="/" />
        </Switch>
      </>
    </Router>
  );
};

export default Routes;
