import React from 'react';
import { Row } from 'react-bootstrap';
import logo from '../../assets/images/white_logo_big.svg';
import contact from '../../assets/images/contact.svg';
import './MainFooter.scss';

const MainFooter = () => (
  <footer>
    <Row noGutters className="footer-content">
      <img className="logo" src={logo} alt="MIN Capital" />
      <span>© 2020 Min Capital All rights reserved</span>
      <img className="contact" src={contact} alt="contacto@mincapital.cl" />
    </Row>
  </footer>
);

export default MainFooter;
