import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const MainRoute = ({ exact, path, component: Component, layout: Layout }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

MainRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

MainRoute.defaultProps = {
  exact: false,
};

export default MainRoute;
