import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import financialMap from '../../assets/images/financial_map.svg';
import financialHeader from '../../assets/images/financial.png';

const Financial = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row noGutters className="main-header-img">
        <img src={financialHeader} alt="" />
      </Row>
      <Row noGutters className="main-content">
        <Col md={12} lg={4} className="main-container-border">
          <Row
            noGutters
            className="mr-0 mr-lg-5 h-100 justify-content-center align-items-center"
          >
            <img className="map" src={financialMap} alt="Perú, Chile" />
          </Row>
        </Col>
        <Col md={12} lg={8}>
          <Row noGutters className="ml-0 ml-lg-5 financial-section">
            <Col>
              <h1 className="mb-5">{t('financial.title')}</h1>
              <p>{t('financial.info')}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Financial;
