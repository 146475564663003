import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Routes from './routes';
import './assets/styles/app.scss';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Routes />
    </I18nextProvider>
  );
};

export default App;
