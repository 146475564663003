import React from 'react';
import Slider from 'react-slick';
import { Row } from 'react-bootstrap';
import SliderArrow from '../SliderArrow';
import firstSlide from '../../assets/images/first_slide.jpg';
import secondSlide from '../../assets/images/second_slide.jpg';
import thirdSlide from '../../assets/images/third_slide.jpg';
import fourthSlide from '../../assets/images/fourth_slide.jpg';
import './LandingSlider.scss';

const LandingSlider = () => {
  const images = [firstSlide, secondSlide, thirdSlide, fourthSlide];

  return (
    <Row noGutters className="landing-slider-container">
      <Slider
        arrows
        className="landing-slider"
        infinite
        speed={500}
        autoplay
        autoplaySpeed={10000}
        pauseOnHover
        slidesToShow={1}
        fade
        slidesToScroll={1}
        prevArrow={<SliderArrow isPrev />}
        nextArrow={<SliderArrow />}
      >
        {images.map(slide => (
          <img key={slide} src={slide} alt="" />
        ))}
      </Slider>
    </Row>
  );
};

export default LandingSlider;
