import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translationEs from './locales/es/translations.json';
import translationEn from './locales/en/translations.json';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_LOCALE || 'es',
    fallbackLng: process.env.REACT_APP_LOCALE || 'es',
    interpolation: {
      escapeValue: false,
    },
    ns: ['translations'],
    defaultNS: 'translations',

    resources: {
      es: {
        translations: translationEs,
      },
      en: {
        translations: translationEn,
      },
    },
  });

export default i18n;
