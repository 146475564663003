import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/images/white_logo.svg';
import menu from '../../assets/images/menu.svg';
import './Nav.scss';

const MainNav = () => {
  const { t, i18n } = useTranslation();
  return (
    <Navbar expand="sm" variant="main">
      <Link to="/" className="logo-link">
        <img className="logo" src={logo} alt="MIN Capital" />
      </Link>
      <Navbar.Toggle>
        <img src={menu} alt="Menu" />
      </Navbar.Toggle>
      <Navbar.Collapse>
        <Nav className="links-container">
          <Link to="/">{t('nav.firstLink')}</Link>
          <Link to="/real-estate">{t('nav.secondLink')}</Link>
          <Link to="/financial">{t('nav.thirdLink')}</Link>
        </Nav>
        <Nav className="languages">
          <button type="button" onClick={() => i18n.changeLanguage('es')}>
            Español
          </button>
          /
          <button type="button" onClick={() => i18n.changeLanguage('en')}>
            English
          </button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MainNav;
