import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import Slider from 'react-slick';
import Arrows from '../SliderArrow';

const RealEstateModal = ({ isOpen, close, active, photos }) => {
  return (
    <Modal
      blockScroll={false}
      open={isOpen}
      onClose={close}
      center
      classNames={{ modal: 'real-estate-modal', closeButton: 'modal-close-circle' }}
    >
      <div className="row no-gutters real-estate-container">
        <Slider
          className="real-estate-photos-slider"
          dots={false}
          draggable={false}
          infinite
          swipe={false}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={active}
          prevArrow={<Arrows isPrev />}
          nextArrow={<Arrows />}
        >
          {photos.map(photo => (
            <React.Fragment key={photo}>
              <div className="real-estate-slide">
                <img src={photo} alt="" />
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </div>
    </Modal>
  );
};

RealEstateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default RealEstateModal;
