import React from 'react';
import PropTypes from 'prop-types';
import chevron from '../../assets/images/chevron.svg';

const SliderArrow = ({ onClick, isPrev }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={onClick}
      className={isPrev ? 'slick-arrow prev' : 'slick-arrow next'}
      onClick={onClick}
    >
      <img src={chevron} alt="" />
    </div>
  );
};

SliderArrow.propTypes = {
  isPrev: PropTypes.bool,
  onClick: PropTypes.func,
};

SliderArrow.defaultProps = {
  isPrev: false,
  onClick: () => {},
};

export default SliderArrow;
