import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import realEstateIcon from '../../assets/images/real_estate.svg';
import realEstateHeader from '../../assets/images/plaza.jpg';
import santaClaraConstruction from '../../assets/images/santa_clara_1.png';
import santaClaraView from '../../assets/images/santa_clara_2.png';
import firstPilotView from '../../assets/images/pilot_1.png';
import secondPilotView from '../../assets/images/pilot_2.png';
import santaClaraConstructionBig from '../../assets/images/santa_clara_1_big.png';
import santaClaraViewBig from '../../assets/images/santa_clara_2_big.png';
import firstPilotViewBig from '../../assets/images/pilot_1_big.png';
import secondPilotViewBig from '../../assets/images/pilot_2_big.png';
import RealEstateModal from '../../components/RealEstateModal';

const RealEstate = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const bottomImages = [
    santaClaraConstruction,
    santaClaraView,
    firstPilotView,
    secondPilotView,
  ];

  const bigBottomImages = [
    santaClaraConstructionBig,
    santaClaraViewBig,
    firstPilotViewBig,
    secondPilotViewBig,
  ];

  const handleImageClick = index => {
    setActiveImage(index);
    setIsModalOpen(true);
  };

  return (
    <>
      <Row noGutters className="main-header-img">
        <img src={realEstateHeader} alt="" />
      </Row>
      <Row noGutters className="main-content">
        <Col md={12} lg={4} className="main-container-border">
          <Row noGutters className="mr-0 mr-lg-5 h-100">
            <div className="d-flex flex-column justify-content-between">
              <div className="main-icon">
                <img src={realEstateIcon} alt="" />
              </div>
              <div>
                <p className="main-column-info">{t('realEstate.column')}</p>
              </div>
            </div>
          </Row>
        </Col>
        <Col md={12} lg={8}>
          <Row noGutters className="ml-0 ml-lg-5">
            <Col>
              <h1 className="mb-5">{t('realEstate.title')}</h1>
              <p>{t('realEstate.info')}</p>
              <Row noGutters className="mt-5 pt-3">
                {bottomImages.map((image, i) => (
                  <Col
                    onClick={() => handleImageClick(i)}
                    key={image}
                    className="main-box-container"
                  >
                    <img src={image} alt="" />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <RealEstateModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        photos={bigBottomImages}
        active={activeImage}
      />
    </>
  );
};

export default RealEstate;
